import {
  required,
  minLength
} from 'vuelidate/lib/validators';

export default () => {
  return {
    role: { required },
    itemsVesselTypeChecked: { required, minLength: minLength(1) }
  }
}

export const RoleFormEditValidations = () => {
  return {
    role: { required },
    itemsVesselTypeChecked: { required }
  }
}